import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'

import Layout from '../components/layout'
import SEO from '../components/seo'
import FeaturedHero from './../components/FeaturedHero'
import ContactForm from './../components/ContactForm'
import LocationItem from './../components/LocationItem'

import './../components/Contact.scss'

import { Waypoint } from 'react-waypoint'

export const query = graphql`
  {
    allPrismicGlobal {
      edges {
        node {
          data {
            locations {
              thumb {
                alt
                fluid(maxWidth: 1000) {
                  src
                }
              }
              address {
                html
              }
              email
              phone
              title {
                text
              }
              link {
                uid
                target
              }
            }
          }
        }
      }
    }
    allPrismicContactPage {
      edges {
        node {
          data {
            meta_description
            meta_title
            canonical {
              uid
              type
            }
            title {
              text
            }
            description {
              text
            }
            location_title {
              text
            }
          }
          uid
        }
      }
    }
  }
`

const ContactPage = (props) => {
  const { location } = props

  const { uid, data } = props.data.allPrismicContactPage.edges[0].node
  const locations = props.data.allPrismicGlobal.edges[0].node.data.locations

  const meta = {
    descripition: data.meta_description,
    title: data.meta_title,
    uid: data.canonical.uid,
    pageType: data.canonical.type,
  }

  const [active, setActive] = React.useState(false)

  const onEnter = () => {
    if (!active) {
      setActive(true)
    }
  }

  return (
    <Layout location={location}>
      <SEO
        meta={meta}
        description={meta.descripition}
        title={meta.title}
        uid={meta.uid}
        pageType={meta.pageType}
      />
      <div className="contact page">
        <FeaturedHero
          title={data.title.text}
          description={data.description.text}
          label="contact"
          type="contact"
          image={require('./../assets/svg/hero-contact.svg')}
          buttons="true"
        />
        <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
          <div
            className={`form-outer  waypoint ${
              active ? 'waypoint-active' : ''
            }`}
          >
            <div className="container">
              <div className="row">
                <div className="col contact-locations">
                  <h4 className="heading heading--s text--black">
                    {data.location_title.text}
                  </h4>
                  <div className="locations">
                    {locations &&
                      locations.map((location, index) => {
                        return (
                          <LocationItem
                            key={`loaction-${index}`}
                            location={location}
                            showThumb={true}
                            styled={true}
                          />
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Waypoint>
      </div>
    </Layout>
  )
}

ContactPage.query = query

export default withPreview(ContactPage)
