import React, { useState } from "react";
import "./ContactForm.scss";
import { navigate } from "gatsby";
const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [formState, setFormState] = useState({
    first: "",
    last: "",
    phone: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    first: false,
    last: false,
    phone: false,
    email: false,
    message: false,
  });

  const checkForErorrs = () => {
    let isValid = true;

    const errors = {
      first: false,
      last: false,
      phone: false,
      email: false,
      message: false,
    };

    if (formState.first.length === 0) {
      isValid = false;
      errors.first = true;
    }
    if (formState.last.length === 0) {
      isValid = false;
      errors.last = true;
    }
    if (formState.phone.length === 0) {
      isValid = false;
      errors.phone = true;
    }
    if (formState.email.length === 0) {
      isValid = false;
      errors.email = true;
    }
    if (formState.message.length === 0) {
      isValid = false;
      errors.message = true;
    }

    setFormErrors(errors);

    return isValid;
  };

  const BASE_PATH =
    typeof window !== `undefined` &&
    window.location.host.indexOf("colormatics.com") > -1
      ? ""
      : "https://colormatics.netlify.app";

  const encodeForm = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkForErorrs()) {
      setLoading(true);
      fetch(BASE_PATH + "/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeForm({
          "form-name": "contact",
          ...formState,
        }),
      })
        .then(() => {
          setLoading(false);
          setComplete(true);
          navigate("/contact/thank-you");
        })
        .catch((error) => {
          console.error("Invalid Form", error);
          setLoading(false);
          setComplete(true);
        });
    }
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  if (complete) {
    return (
      <div>
        <h3>Thank you for submitting.</h3>
        <p>Someone will be in contact with you soon.</p>
      </div>
    );
  }

  return (
    <form
      className="contact-form"
      name="contact"
      data-netlify="true"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <div class="form-control">
        <label>First Name</label>
        <input
          type="text"
          name="first"
          value={formState.first}
          placeholder="First Name"
          className={formErrors.first ? "error" : ""}
          onChange={handleChange}
        />
      </div>
      <div class="form-control">
        <label>Last Name</label>
        <input
          type="text"
          name="last"
          value={formState.last}
          className={formErrors.last ? "error" : ""}
          placeholder="Last Name"
          onChange={handleChange}
        />
      </div>
      <div class="form-control">
        <label>Phone</label>
        <input
          type="tel"
          name="phone"
          value={formState.phone}
          className={formErrors.phone ? "error" : ""}
          placeholder="(XXX) XXX-XXXX"
          onChange={handleChange}
        />
      </div>
      <div class="form-control">
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={formState.email}
          className={formErrors.email ? "error" : ""}
          placeholder="Email Address"
          onChange={handleChange}
        />
      </div>
      <div class="form-control">
        <label>Message</label>
        <textarea
          type="text"
          name="message"
          value={formState.message}
          className={formErrors.message ? "error" : ""}
          placeholder="Enter your message..."
          onChange={handleChange}
        />
      </div>
      <div className="button-group align-center">
        <button className="button button-block button-round button-gray">
          {loading ? "Sending..." : "Submit"}
        </button>
      </div>
    </form>
  );
};
export default ContactForm;
